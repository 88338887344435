import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import ScrollToTop from "./components/ScrollToTop";
import VoorbeeldTraject from './pages/VoorbeeldTraject'
import HomeEnglish from './pages/HomeEnglish'
import SampleTrajectory from "./pages/SampleTrajectory";

function App() {
    return (
        <Router>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/VoorbeeldTraject" element={<VoorbeeldTraject/>}/>
                <Route path="/en" element={<HomeEnglish/>}/>
                <Route path="/en/sampletrajectory" element={<SampleTrajectory/>}/>
            </Routes>
        </Router>
    );
}

export default App;
