import React from "react";
import Footer from "../components/Footer";
import DutchRedirect from "../components/DutchRedirect";
import FrontPageEnglish from "../components/FrontPageEnglish";
import CardsEnglish from "../components/CardsEnglish";

function HomePageEnglish() {
    return (
        <div className="App mb-0 bg-custom ">
            <div className="flex flex-end">
                <DutchRedirect/>
            </div>
            <FrontPageEnglish/>
            <CardsEnglish/>
            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default HomePageEnglish;
