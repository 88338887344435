import headshot from "../../images/headshotinchair.jpeg";
import React from "react";

const Headshot = () => {
  return <div className="grid">
    <img
      alt="headshot"
      className="flex max-w-xl sm:m-24 m-12 sm:w-full w-96 border-8 border-slate-600 rounded-box mb-24 justify-self-center"
      src={headshot}
    />
  </div>;
}

export default Headshot