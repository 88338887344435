import React from "react";
import HomePage from "../pages/HomePage";

function Home() {
  return (
    <div data-theme="" className="App bg-base-200 mb-0">
      <HomePage/>
    </div>
  );
}

export default Home;
