import React from "react";

function Card1() {
  return (
    <article className="text-white border-8 sm:p-24 m-12 p-12 rounded-box border-slate-600 mb-24">
      <ul>
        <li>
          <strong className="text-lg">• Fase 1: Wie ben ik?</strong>
          <br/>
          In deze fase word je je bewust van wie je eigenlijk bent en hoe
          je in je leven bent gevormd. Je maakt een zelfanalyse en krijgt
          een scherper zelfbeeld.
        </li>
        <br/>
        <li>
          <strong className="text-lg">• Fase 2: Wat kan ik?</strong>
          <br/>
          In fase 2 maken we een analyse waardoor je je bewust wordt van
          je drijfveren en talenten. Waarom doe je wat je doet? Wat doe je
          graag en wat kun je goed? Ook kijken we naar je persoonlijke
          waarden. Wat is voor jou belangrijk in het leven en in je werk?
        </li>
        <br/>
        <li>
          <strong className="text-lg">• Fase 3: Wat wil ik?</strong>
          <br/>
          In de laatste fase heb je een helder beeld van jezelf. Je weet
          in welke branche (of zelfs bedrijf) je wilt werken en misschien
          zelfs in welke functie je graag aan de slag wilt. Wie weet
          besluit je het roer om te gooien of een studie te starten. Na
          deze fase kun je zelf verder.
        </li>
      </ul>
      <p>
        <br/>
        Wil je vrijblijvend kennismaken? Neem dan contact met mij op, dan
        zien we elkaar snel.
        <br/>
        <strong>Het is nu tijd voor jou!</strong>
      </p>
    </article>
  );
}

export default Card1