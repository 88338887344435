import React from "react";
import ExampleRedirectEnglish from "./ExampleRedirectEnglish";

const Cards = () => {
    return (
        <>
            <div className="flex sm:justify-evenly sm:space-x-4 sm:m-24 m-12 flex-col sm:flex-row">
                <div className="card w-full text-primary-content border-8 border-slate-600">
                    <div className="card-body">
                        <h2 className="card-title">Personal Coaching</h2>
                        <article className="flex flex-col">
                            <ul>
                                <li>• Do you feel like you've lost yourself?</li>
                                <li>• Do you no longer know what you want?</li>
                                <li>• Do you doubt yourself?</li>
                                <li>• Do you experience inner unrest or are you facing difficult choices?</li>
                                <li>• Do you want more insight into your qualities and talents?</li>
                                <li>• Or do you want more insight into what it's all about for you right now?</li>
                            </ul>
                            <br/>
                            <p>
                                Personal coaching helps you when you don't know yourself anymore and could use some
                                guidance.
                                <br/>
                                It can be about all kinds of circumstances. For example, balancing work-life, increasing
                                self-confidence, better handling stress, communicating differently with your
                                environment, etc.
                                <br/>
                                <br/>
                                In a personal deepening trajectory, we work together. I use special coaching techniques
                                and proven models. Once your goal is clear and we know what choices there are, we will
                                look together at how to create an action plan. After a non-binding introductory
                                conversation, I can give you an estimate of the duration of the trajectory. Sometimes a
                                few conversations are enough to gain more clarity and confidence to find the direction
                                to your goal. An average trajectory consists of 5 to 6 sessions, which last 50 to 60
                                minutes each.
                            </p>
                        </article>
                        <div className="card-actions justify-end"></div>
                    </div>
                </div>
                <div className="card w-full bg-wittebol text-primary-content border-8 sm:my-0 my-8 border-slate-600">
                    <div className="card-body">
                        <h2 className="card-title">Career Coaching</h2>
                        <article>
                            <p>
                                Have you been unhappy at work for a while? Do you doubt whether you're still in the
                                right place? Are you forced to look for something else due to a reorganization or
                                bankruptcy? Or do you want something else but don't know what it could be? Then this
                                career coaching trajectory is for you.
                            </p>
                            <br/>
                            <p>
                                You go through three phases in this six individual sessions career trajectory:
                            </p>
                            <ul>
                                <li>• Who am I?</li>
                                <li>• What can I do?</li>
                                <li>• What do I want?</li>
                            </ul>
                            <br/>
                            A solid foundation is laid by paying a lot of attention to who you are.
                            <br/>
                            <br/>
                            Then we analyze your motives, your talents are addressed, and you get a clear view of your
                            qualities.
                            <br/>
                            <br/>
                            Between the conversations, you will work on assignments yourself. At the end of the
                            trajectory, you will know better who you are, what you can do, and what you want, and you
                            can take a targeted next step.
                            <br/>
                        </article>
                        <div className="card-actions justify-end"></div>
                    </div>
                </div>
                <div className="card w-full text-primary-content border-8 border-slate-600">
                    <div className="card-body">
                        <h2 className="card-title">About me:</h2>
                        <article>
                            <p>
                                In 2022, I started my own coaching practice Wittebol-Coaching. I guide women (in Dutch
                                or English) who no longer get energy from their current job and want to change something
                                about it.
                                <br/>
                                <br/>
                                Before starting my own practice, I worked in international financial services. From my
                                business empathy, I know how to give you confidence and tools so that you can work on
                                the situation you would like to see differently. I do this both in Dutch and English.
                                After your trajectory, you will know who you are again and what you want, and you dare
                                to go for it again.
                                <br/>
                                <br/>
                                Would you like to meet without obligation? Then contact me, and we'll see each other
                                soon.
                                <br/>
                                <strong>It's time for you now!</strong>
                            </p>
                        </article>
                        <div className="card-actions justify-end"></div>
                    </div>
                </div>
            </div>
            <ExampleRedirectEnglish/>
        </>
    );
};

export default Cards;
