import React from "react";
import {Link} from "react-router-dom";


const ExampleRedirect = () => {
    return (
        <div className="flex sm:mb-24 m-12 justify-center">
            <Link to='/VoorbeeldTraject'>
                <button className="btn btn-secondary text-white ">
                    Voorbeeld van een mogelijk loopbaan traject vind je hier!
                </button>
            </Link>
        </div>
    );
};

export default ExampleRedirect;
