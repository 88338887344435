import React from "react";
import {Link} from "react-router-dom";
import flag from "../images/englishFlag.png"

const EnglishRedirect = () => {
    return (
        <div>
            <Link to="/en">
                <button className="btn btn-secondary m-4">
                    Click here for English
                    <img src={flag} alt="english flag for language switcher" className="w-12"/>
                </button>
            </Link>
        </div>
    );
};

export default EnglishRedirect;
