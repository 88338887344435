import React from "react";
import headshot from "../images/Headshot.jpeg";

const FrontpageEnglish = () => {
    return (
        <>
            <div className="flex justify-center mt-12 mx-12">
                <h1 className="text-3xl text-white border-4 rounded-box p-12 border-slate-600 sm:hidden">
                    Wittebol-Coaching
                    <p className="text-sm text-white ">
                        <br/>Do you feel like you've stumbled into everything but actually want it differently, but
                        don't know how?
                        <br/>
                        You've come to the right place at Wittebol-Coaching.
                    </p>
                </h1>
            </div>

            <div className="flex justify-evenly flex-wrap ">
                <div className="flex sm:flex-row flex-col-reverse sm:items-center w-2/3 mt-24">
                    <img
                        className="rounded-full w-96 border-8 border-slate-600 mr-12"
                        src={headshot}
                        alt="logo"
                    />
                    <div>
                        <h1 className="text-primary-content text-xl">
                            Who am I:<br/>Ans Wittebol, married and mother of two sons. <br/>
                            <br/>
                            <h1 className="hidden sm:contents">
                                Personal characteristics that describe me are:
                                approachable, connecting, enthusiastic, direct, result-oriented,
                                honest, and respectful.
                            </h1>
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FrontpageEnglish;
