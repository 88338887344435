import React from "react";
import headshot from "../images/headshotinchair.jpeg";
import Footer from "../components/Footer";

const SampleTrajectory = () => {
    return (
        <>
            <div className="flex sm:mt-12  flex-col justify-center items-center bg-primary">
                <img
                    alt="headshot"
                    className="flex max-w-xl sm:m-24 m-12 sm:w-full w-96 border-8 border-slate-600 rounded-box mb-24 "
                    src={headshot}
                />
                <div className="border-8 sm:p-24 m-12 p-12 rounded-box border-slate-600 mb-24">
                    <article className="sm:w-96 text-white">
                        <ul>
                            <li>
                                <strong className="text-lg">• Phase 1: Who am I?</strong>
                                <br/>
                                In this phase, you become aware of who you really are and how
                                you have been shaped in your life. You perform a self-analysis
                                and gain a clearer self-image.
                            </li>
                            <br/>
                            <li>
                                <strong className="text-lg">• Phase 2: What can I do?</strong>
                                <br/>
                                In phase 2, we conduct an analysis that makes you aware of your
                                motives and talents. Why do you do what you do? What do you
                                enjoy doing and what are you good at? We also look at your
                                personal values. What is important to you in life and in your
                                work?
                            </li>
                            <br/>
                            <li>
                                <strong className="text-lg">• Phase 3: What do I want?</strong>
                                <br/>
                                In the final phase, you have a clear image of yourself. You know
                                in which industry (or even company) you want to work and maybe
                                even in which position you would like to work. Who knows, you
                                may decide to change course or start a new study. After this
                                phase, you can continue on your own.
                            </li>
                        </ul>
                        <p>
                            <br/>
                            Would you like to meet without obligation? Then contact me, and
                            we'll see each other soon.
                            <br/>
                            <strong>It's time for you now!</strong>
                        </p>
                    </article>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default SampleTrajectory;
