import React from "react";
import CardsContainer from "../components/CardsContainer";
import Frontpage from "../components/Frontpage";
import Footer from "../components/Footer";
import EnglishRedirect from "../components/EnglishRedirect";

function HomePage() {
  return (
    <div className="App mb-0 bg-custom ">
      <div className="flex flex-end">
        <EnglishRedirect/>
      </div>
      <Frontpage/>
      <CardsContainer/>
      <div>
        <Footer/>
      </div>
    </div>
  );
}

export default HomePage;
