import React from "react";
import logo from '../images/logoUpscaled.png'

export const Footer = () => {
    return (
        <div className="">
            <footer className="bg-base-300 text-white py-8 w-full">
                <div className="container mx-auto flex justify-between items-center">
                    <div>
                        <p className="text-xs sm:text-lg text-gray-400 ml-4 mr-4 ">
                            <p className="text-secondary font-bold">Let's Connect!</p>
                            <strong>TEL:</strong> <a className="underline" href="tel:+31 6 54984886">06-54 98 48
                            86</a><br/>
                            <strong>Linkedin:</strong> <a className="underline"
                                                          href="https://www.linkedin.com/in/ans-wittebol-9ab75b12/">Mijn
                            profiel</a><br/>
                            <strong>E-Mail:</strong> <a className="underline"
                                                        href="mailto:wittebol.coaching@gmail.nl">wittebol.coaching@gmail.com</a><br/>
                            <strong>KVK:</strong> 88723984
                        </p>
                    </div>
                    <img className="m-5 sm:w-48 w-36 rounded-box" alt="logo" src={logo}/>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
