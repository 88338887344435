import React from "react";
import {Link} from "react-router-dom";

const ExampleRedirectEnglish = () => {
    return (
        <div className="flex sm:mb-24 m-12 justify-center">
            <Link to='/en/sampletrajectory'>
                <button className="btn btn-secondary text-white ">
                    Example of a possible career trajectory can be found here!
                </button>
            </Link>
        </div>
    );
};

export default ExampleRedirectEnglish;
