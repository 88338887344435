import React from "react";
import ExampleRedirect from "./ExampleRedirect";

const CardsContainer = () => {
  return (
    <>
      <div className="flex sm:justify-evenly sm:space-x-4 sm:m-24 m-12 flex-col sm:flex-row">
        <div className="card w-full text-primary-content border-8 border-slate-600">
          <div className="card-body">
            <h2 className="card-title">Persoonlijke coaching</h2>
            <article className="flex flex-col">
              <ul>
                <li>• Heb je het gevoel jezelf te zijn kwijtgeraakt?</li>
                <li>• Weet je niet meer wat je wilt?</li>
                <li>• Twijfel je aan jezelf?</li>
                <li>
                  • Ervaar je innerlijke onrust of sta je voor moeilijke keuzes?
                </li>
                <li>• Wil je meer zicht op je kwaliteiten en talenten?</li>
                <li>
                  • Of wil je meer zicht krijgen op waar het voor jou nu
                  &nbsp;&nbsp;&nbsp;allemaal om draait?
                </li>
              </ul>
              <br/>
              <p>
                Persoonlijke coaching helpt je wanneer je het zelf even niet
                meer weet en een aantal handvatten kunt gebruiken.
                <br/>
                Het kan over
                allerlei omstandigheden gaan. Bijvoorbeeld balans werk-vrije
                tijd, vergroten van je zelfvertrouwen, beter leren hanteren van
                stress, andere manier van communiceren met je omgeving, etc.
                <br/>
                <br/>
                In een persoonlijk verdiepingstraject gaan we samen aan de slag.
                Daarbij hanteer ik speciale coachtechnieken en beproefde
                modellen. Is jouw doel eenmaal helder en weten we welke keuzes
                er zijn dan kijken we samen hoe we tot een actieplan komen.
                <br/>
                Na een vrijblijvend kennismakingsgesprek kan ik je een inschatting
                geven van de duur van het traject. Soms zijn een paar gesprekken
                al voldoende om te komen tot meer helderheid en zelfvertrouwen
                om de richting naar je doel te vinden. Een gemiddeld traject
                bestaat uit 5 à 6 sessies, die 50 tot 60 minuten duren.
              </p>
            </article>
            <div className="card-actions justify-end"></div>
          </div>
        </div>
        <div className="card w-full bg-wittebol text-primary-content border-8 sm:my-0 my-8 border-slate-600">
          <div className="card-body">
            <h2 className="card-title">Loopbaan coaching</h2>
            <article>
              <p>
                Ben je al een poosje niet gelukkig in je werk? Twijfel je er aan
                of je nog wel op de juiste plek zit? Word je door een
                reorganisatie of faillissement gedwongen om op zoek te gaan naar
                iets anders? Of wil je graag iets anders, maar weet niet wat dat
                dan zou kunnen zijn? Dan is dit traject van loopbaan coaching
                iets voor jou.
              </p>
              <br/>
              <p>
                <strong>
                  Je doorloopt drie fases in dit loopbaantraject van zes
                  individuele sessies:
                </strong>
              </p>
              <ul>
                <li>• Wie ben ik?</li>
                <li>• Wat kan ik?</li>
                <li>• Wat wil ik?</li>
              </ul>
              <br/>
              Er wordt een stevig fundament gelegd door veel aandacht besteden
              aan wie je bent.
              <br/>
              <br/>
              Vervolgens analyseren we je drijfveren, komen je
              talenten aan bod en krijg je een helder zicht op je kwaliteiten.
              <br/>
              <br/>
              Tussen de gesprekken door ga je zelf aan de slag met opdrachten.
              Aan het einde van het traject weet je beter wie je bent, wat je
              kunt en wat je wilt en kun je een gerichte vervolgstap maken.
              <br/>
            </article>
            <div className="card-actions justify-end"></div>
          </div>
        </div>
        <div className="card w-full text-primary-content border-8 border-slate-600">
          <div className="card-body">
            <h2 className="card-title">Sollicitatie begeleiding</h2>
            <article>
              <p>
                Je wilt solliciteren, maar het is misschien al wel een hele tijd geleden dat je een CV en motivatie
                brief of een personal branding document gemaakt hebt. Verder weet je niet welke vragen je tegenwoordig
                op een sollicitatiegesprek kan verwachten.
                <br/>
                <br/>
                <strong>Ik kan je begeleiden middels:</strong>
                <ol>
                  <li>
                    Het geven van feedback op je CV, motivatie brief en je personal branding document;
                  </li>
                  <br/>
                  <li>
                    Het samen oefenen met mogelijke (lastige) sollicitatievragen die in jouw specifieke situatie gesteld
                    zouden kunnen worden.
                  </li>
                </ol>
                <br/>
                <h2><strong>Resultaten</strong></h2>
                <ul>
                  <li className='list-item '>
                    Je CV en brief zijn afgestemd op de vacature en lezer en geven een goed beeld van wie jij bent en
                    wat jij te bieden hebt.
                  </li>
                  <br/>
                  <li>
                    Je bent goed voorbereid en hebt meer zelfvertrouwen om te gaan solliciteren, je kunt jezelf helder
                    presenteren, je kan je kwaliteiten aan de hand van voorbeelden illustreren en je hebt geoefend met
                    lastige sollicitatievragen.
                  </li>
                  <br/>
                </ul>
              </p>
            </article>
            <div className="card-actions justify-end"></div>
          </div>
        </div>
      </div>
      <ExampleRedirect/>
    </>
  );
};

export default CardsContainer;
