import React from "react";
import Card from "../Cards/AboutMe";
import Card1 from "../Cards/Trail"

const CardsContainer = () => {
  return (
    <div id="cardsContainer" className="flex sm:mt-12">

      <Card1/>
      <Card/>

    </div>
  );
}
export default CardsContainer