import React from "react";

const Card = () => {
  return (
    <article className="border-8 sm:p-24 m-12 p-12 rounded-box border-slate-600 mb-24 text-white">
      <h2 className="card-title">Over mij:</h2>
      <p>
        Tijdens mijn vorige baan bij een grote internationale financiële instelling was ik
        jarenlang betrokken als consulent arbeidsrelaties bij conflictsituaties tussen
        medewerkers en manager. Naast mediation, kwam ik ook in aanraking met coaching, dat
        laatste paste mij erg goed. In 2021 heb ik daarom een erkende beroepsopleiding tot coach
        practitioner gedaan, gevolgd door opleidingen loopbaan coaching en positieve
        psychologie. Al tijdens mijn opleiding ben ik cliënten gaan begeleiden en dat beviel me
        zo goed dat ik in December 2022 Wittebol-Coaching heb opgericht, mijn eigen coach
        praktijk.
        <br/>
        <br/>
        Ik begeleid cliënten die geen energie meer krijgen van hun huidige baan en daar iets aan
        willen veranderen. Vanuit mijn zakelijk inlevingsvermogen weet ik je zelfvertrouwen en
        handvatten te geven, zodat je kunt werken aan de situatie die je graag anders wilt zien.
        Dat doe ik dus zowel in het Nederlands als het Engels. Na afloop van jouw traject weet
        je weer wie jij bent en wat jij wilt en durf je er weer voor te gaan.
        <br/>
        <br/>
        Wil je vrijblijvend kennismaken?
        <br/>
        Neem dan contact met mij op, dan zien we elkaar snel.
        <br/>
        <br/>
        <strong>Het is nu tijd voor jou!</strong>
      </p>
    </article>
  );
}

export default Card