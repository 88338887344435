import React from "react";
import headshot from "../images/Headshot.jpeg";

const Frontpage = () => {
  return (
    <>
      <div className="flex justify-center mt-12 mx-12">
        <h1 className="text-3xl text-white border-4 rounded-box p-12 border-slate-600 sm:hidden">
          Wittebol-Coaching 
          <p className="text-sm text-white ">
          <br />Heb je het gevoel overal ingerold te zijn maar wil je het eigenlijk anders, maar weet je niet hoe?
          <br />
           Bij Wittebol-Coaching ben je aan het juiste adres.
          </p>
        </h1>
      </div>

      <div className="flex justify-evenly flex-wrap ">
        <div className="flex sm:flex-row flex-col-reverse sm:items-center w-2/3 mt-24">
          <img
            className="rounded-full w-96 border-8 border-slate-600 mr-12"
            src={headshot}
            alt="logo"
          />
          <div>
            <h1 className="text-primary-content text-xl">
              Wie ben ik:<br />Ans Wittebol, getrouwd en moeder van twee zonen. <br />
              <br />
              <h1 className="hidden sm:contents">
                Persoonlijke eigenschappen welke mij omschrijven zijn:
                toegankelijk, verbindend, enthousiast, direct, resultaatgericht,
                eerlijk en respectvol.
              </h1>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Frontpage;
