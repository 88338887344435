import React from "react";
import { Link } from "react-router-dom";

const Backbutton = () => {
  return (
    <div>
      <Link to="/">
        <button className="btn btn-secondary m-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </Link>
    </div>
  );
};

export default Backbutton;
