import React from "react";
import Footer from "../components/Footer";
import Backbutton from "../components/Backbutton";
import Headshot from "../components/Headshot/Headshot";
import CardsContainer from "../components/Containers/CardsContainer";

const VoorbeeldTraject = () => {
  return (
    <>
      <Backbutton/>
      <Headshot/>
      <CardsContainer/>
      <Footer/>
    </>
  );
};

export default VoorbeeldTraject;
